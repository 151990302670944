// box-1 text のアニメーション
function textAnimation() {
    const triggerElm = document.querySelector('.loading-wrapper');
    const firstShape = document.querySelector('.first-shape');
    const secoundShape = document.querySelector('.secound-shape');

    triggerElm.addEventListener('animationend', (e) => {
        // イベントの発生元が #loadingかを判定
        if(e.target.id == 'loading') {
            firstShape.style.animationName = 'disappear-shape';
        }
    });

    firstShape.addEventListener('animationend', () => {
        secoundShape.style.animationName = ' disappear-shape';
    });
}

textAnimation();

// section3の調整
function tagPositionPC() {
    const target = document.querySelector('.tag-reference');
    const targetSP = document.querySelector('.tag-reference-sp');
    const example = document.querySelectorAll('.example');
    const worksButton = document.querySelector('.button-works');
    const exampleTitle = document.querySelector('.example-title');

    if(example.length == 1 || example.length == 3) {
        target.style.left = 0;
        target.style.bottom = '24px';
    } else if(example.length == 2) {
        target.style.right = 0;
        target.style.bottom = '24px';
    }　else {
        target.style.display = 'none';
        worksButton.style.display = 'none';
        exampleTitle.style.display = 'none';
        targetSP.style.display = 'none';
    }
}

tagPositionPC();

window.addEventListener('resize', () => {
    tagPositionPC();
});